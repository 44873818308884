(function ($) {
	/**
     * Inicjalizacja podstawowych
     */
    makeTablesResponsive();
    makeSlickSliders();
    makeSmoothScrolling();
    makeModalHeaderFix();
    makeScrollAccordeon();

    new universalParallax().init({
        speed: 6.0
    });

    var position = $(window).scrollTop(); 
    $(window).on('scroll', function () {
        makeMenuBarFuller();
    });

    /**
     * Funkcja szukająca tabeli wewnątrz tekstów pobranych z Wordpress'owego WYSIWYG
     * i "obudowująca" tabele w "table-responsive".
     * @version 1.0.0
     */
    function makeTablesResponsive () {
        let table = $('.wysiwyg-content').find('table');

        if (0 < table.length) {
            table.wrap('<div class="table-responsive"></div>');
        }
    }

    /**
     * Funkcja tworząca slidery Slick.
     * @version 1.0.0
     */
    function makeSlickSliders () {
        if (0 < $('.js-home-slider').length) {
            $('.js-home-slider').slick({
                autoplay: true,
                autoplaySpeed: 4000,
                dots: true,
                speed: 1000,
                prevArrow: '<span class="icon-arrow-short-right home-slider__arrow home-slider__arrow--prev"></span>',
                nextArrow: '<span class="icon-arrow-short-right home-slider__arrow home-slider__arrow--next"></span>',
            });
        }

        if (0 < $('.js-home-facebook-slider').length) {
            $('.js-home-facebook-slider').slick({
                autoplay: true,
                autoplaySpeed: 10000,
                dots: true,
                speed: 1000,
                slidesToShow: 4,
                slidesToScroll: 4,
                prevArrow: '<span class="icon-arrow-short-right home-facebook__arrow home-facebook__arrow--prev"></span>',
                nextArrow: '<span class="icon-arrow-short-right home-facebook__arrow home-facebook__arrow--next"></span>',
                responsive: [{
                    breakpoint: 1368,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },{
                    breakpoint: 1024,
                    settings: {
                        autoplay: false,
                        dots: false,
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },{
                    breakpoint: 768,
                    settings: {
                        autoplay: false,
                        centerMode: true,
                        dots: false,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
            });
        }

        if (0 < $('.js-tour-gallery-slider').length) {
            $('.js-tour-gallery-slider').slick({
                infinite: false,
                lazyLoad: 'ondemand',
                speed: 450,
                slidesToShow: 3,
                prevArrow: '<span class="icon-arrow-short-right tour-gallery__arrow tour-gallery__arrow--prev"></span>',
                nextArrow: '<span class="icon-arrow-short-right tour-gallery__arrow tour-gallery__arrow--next"></span>',
                responsive: [{
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },{
                    breakpoint: 768,
                    settings: {
                        autoplay: false,
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
            });
        }
    }

    /**
     * Funkcja zarządzająca mechaniką menu podczas scrollowania.
     * 
     * @version 1.0.0
     */
    function makeMenuBarFuller () {
        let $header = $('.js-header'),
            scroll = $(window).scrollTop();

        if (0 < $header.length) {
            if (60 < $(document).scrollTop()) {
                $header.addClass('is-fuller');
            } else {
                $header.removeClass('is-fuller');
            }

            // Zamykamy menu na mobile
            $('#siteHeaderMenu').collapse('hide');

            // Efekty zależne od kierunku scroll'a
            if(position < scroll && 50 < $(document).scrollTop()) {
                $header.addClass('hide');
            } else {
                $header.removeClass('hide');
            }
            position = scroll;
        }
    }

    /**
     * Funkcja odpowiada za smooth scroll w obrębie całej strony.
     * @version 3.0.0
     */
    function makeSmoothScrolling () {
        $('a[href*="#scroll"]')
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function(event) {
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
                    &&
                    location.hostname == this.hostname
                ) {
                var target = $(this.hash);

                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    event.preventDefault();

                    let targetOffset = target.offset().top;
                    if (target[0]['id'] == 'scroll-page-top') {
                        targetOffset = 0;
                    }

                    $('html, body').animate({
                        scrollTop: targetOffset
                    }, 1000);
                }
            }
        });
    }

    /**
     * Robimy fix dla skaczącego fixed menu w header na otwarciu modal'a.
     * 
     * @version 1.0.0
     */
    function makeModalHeaderFix () {
        $('.modal').on('show.bs.modal', function (e) {
            $('.js-header').css('padding-right', getScrollbarWidth());
            $('.js-floating-social-bar').css('padding-right', getScrollbarWidth());
        });
        $('.modal').on('hidden.bs.modal', function (e) {
            $('.js-header').css('padding-right', 0);
            $('.js-floating-social-bar').css('padding-right', 0);
        });
    }

    /**
     * Funkcja wyciągająca szerokość scroll-bar'a przeglądarki.
     * 
     * @version 1.1.0
     */
    function getScrollbarWidth () {
        let scrollbarWidth = window.innerWidth - document.getElementsByTagName('html')[0].clientWidth;
        return scrollbarWidth;
    }

    /**
     * Funkcja scroll'ujaca do podanego elementu.
     * 
     * @version 1.0.0
     */
    function makeScrollAccordeon () {
        $('.js-home-about-item').on('shown.bs.collapse', function () {
            let $this = $(this);
            $('html, body').animate({
                scrollTop: $this.offset().top - getMenuHeight()
            }, 400);
        })
    }

    /**
     * Funkcja zwracająca wysokość menu głównego.
     * 
     * @version 1.0.0
     */
    function getMenuHeight () {
        return $('.js-header').height();
    }

})(jQuery);
