/**
 * Funkcja odpowiada za filtrowanie aktualności.
 * 
 * @version 1.0.0
 */
(function ($) {
    let $filterArticlesButton = $('.js-filter-articles');

    if (0 < $filterArticlesButton.length) {
        $filterArticlesButton.on('click', function () {
            let selectedYears = [];

            $.each($("input[name='filter_years']:checked"), function(){
                selectedYears.push($(this).val());
            });

            $.ajax({
                url: ajax.url,
                type: 'POST',
                data: {
                    action: 'filter_articles',
                    security: ajax.nonce,
                    selectedYears: selectedYears,
                },
                beforeSend: function () {
                    $('.js-articles-col').addClass('is-filtering');
                    $filterArticlesButton.text('Czekaj');
                    $filterArticlesButton.prop('disabled', true);
                },
                success: function (response) {
                    response = JSON.parse(response);

                    if (response.status == true) {
                        $filterArticlesButton.text('Filtruj');
                        $filterArticlesButton.prop('disabled', false);
                        $('.js-articles-col').html(response.html);
                        $('.js-articles-col').removeClass('is-filtering');
                        
                    } else {
                        $filterArticlesButton.text('Błąd');
                        console.error(response);
                    }
                },
                error: function (xhr, error) {
                    $filterArticlesButton.text('Błąd');
                    console.error(error);
                }
            });

        });
    }

})(jQuery);
