/**
 * Funkcja odpowiada za wyświetlenie Konami Code <3.
 * 
 * @version 1.0.0
 */
(function ($) {

    onKonamiCode();

    function onKonamiCode () {
        var input = '';
        var key = '38384040373937396665';
        document.addEventListener('keydown', function (e) {
            input += ("" + e.keyCode);
            if (input === key) {
                alert('\o/');
            }
            if (!key.indexOf(input)) return;
            input = ("" + e.keyCode);
        });
    }

})(jQuery);
