/**
 * Plik odpowiada za zarządzanie formularzem kontaktowym.
 * @version 1.0.0
 */
(function ($) {

    if (0 < $('.js-contact-form').length) {
        $('.js-contact-form').on('submit', function (event) {
            event.preventDefault();

            let name = $('.js-contact-name'),
                mail = $('.js-contact-mail'),
                phone = $('.js-contact-phone'),
                message = $('.js-contact-message'),
                hnypot = $('.js-hnypot'),
                contactSendButton = $('.js-contact-send-button');

            if (hnypot.val().length <= 0) {
                $.ajax({
                    url: ajax.url,
                    type: 'post',
                    data: {
                        action: 'send_contact_form',
                        security: ajax.nonce,
                        name: name.val(),
                        mail: mail.val(),
                        phone: phone.val(),
                        message: message.val(),
                        hnypot: hnypot.val()
                    },
                    beforeSend: function () {
                        name.attr('disabled', 'disabled');
                        mail.attr('disabled', 'disabled');
                        phone.attr('disabled', 'disabled');
                        message.attr('disabled', 'disabled');
                        contactSendButton.attr('disabled', 'disabled');
    
                        contactSendButton.html('Wysyłam');
                    },
                    success: function (response) {
                        response = JSON.parse(response);
    
                        if (response.status == true) {
                            name.val(null);
                            mail.val(null);
                            phone.val(null);
                            message.val(null);
                            contactSendButton
                                .html('Wysłano')
                                .addClass('is-sent');
                            Swal.fire({
                                type: 'success',
                                title: 'Udało się!',
                                text: 'Wiadomość została wysłana.'
                            });
                        } else {
                            contactSendButton.html('Wyślij wiadomość');
                            name.attr('disabled', false);
                            mail.attr('disabled', false);
                            phone.attr('disabled', false);
                            message.attr('disabled', false);
                            contactSendButton.attr('disabled', false);
    
                            let errorsList = '';
                            response.errors.forEach(element => {
                                errorsList += '<li>' + element + '</li>'; 
                            });
    
                            Swal.fire({
                                type: 'warning',
                                title: 'Uwaga',
                                html: 'W formularzu wystąpiły następujące błędy:'
                                + '<ul>' + errorsList + '</ul>'
                            });
    
                            console.error(response.errors);
                        }
                    },
                    error: function (xhr, error) {
                        contactSendButton
                            .html('Błąd')
                            .addClass('is-error');
                        Swal.fire({
                            type: 'error',
                            title: 'Błąd',
                            text: 'Podczas wysyłki wiadomości wystąpił nieoczekiwany błąd.'
                        });
                    }
                });
            } else {
                Swal.fire({
                    type: 'error',
                    title: 'Błąd',
                    text: 'Prawdopodobna próba wysłania spam-wiadomości!'
                });
            }
            

        });
    }

})(jQuery);
