/**
 * Funkcja odpowiada za wyświetlenie komunikatu o ciasteczkach.
 * 
 * @version 1.1.0
 */
/* (function ($) {

    if (typeof Cookies.get('tommi_tours') === 'undefined'){
        $('.js-cookie-info').show();
        $('.js-accept-cookie').on('click', function (e) {
            e.preventDefault();
            Cookies.set('tommi_tours', 'Cookies!_Om_nom_nom_nom!_*Cookie_Monster_eats_all_cookies*~AlpakaPozdrawia', {
                expires: 31,
                secure: location.protocol == 'https:' ? true : false,
            });
            $('.js-cookie-info').hide();
        })
    }

})(jQuery); */
