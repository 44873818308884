(function ($) {

    $.ajax({
        url: ajax.url,
        data: {
            action: 'instagram_feed'
        },
        type: 'post',
        success: function (response) {
            $('.js-instagram-photos').html(response);
        }
    });

})(jQuery);
