/**
 * Plik odpowiada za zarządzanie dodaniem komentarza do wyjazdu.
 * @version 1.0.0
 */
(function ($) {

    $(window).on('load', function() {
        let $tourCommentForm = $('.js-tour-comment-form');

        $tourCommentForm.on('submit', function (e) {
            e.preventDefault();

            let formData = $tourCommentForm.serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {}),
                sendButton = $('.js-comment-button');

            $.ajax({
                url: ajax.url,
                type: 'POST',
                data: {
                    action: 'tour_comment_add',
                    security: ajax.nonce,
                    data: formData,
                },
                beforeSend: function () {
                    sendButton.text('Czekaj');
                    sendButton.prop('disabled', true);
                },
                success: function (response) {
                    if (response == 'OK') {
                        sendButton.text('Zapisano');
                        $('#collapseCommentForm').collapse('hide');
                        $('#collapseCommentFormEnd').collapse('show');
                    } else {
                        sendButton.text('Błąd');
                        console.error(response);
                    }
                },
                error: function (xhr, error) {
                    sendButton.text('Błąd');
                    console.error(error);
                }
            });

        });
    });

})(jQuery);
