/**
 * Funkcja odpowiada za filtrowanie wycieczek.
 * 
 * @version 1.0.0
 */
(function ($) {
    let $filterToursButton = $('.js-filter-tours');

    if (0 < $filterToursButton.length) {
        $filterToursButton.on('click', function () {
            let selectedCategories = [];

            $.each($("input[name='filter_tour_categories']:checked"), function(){
                selectedCategories.push($(this).val());
            });

            $.ajax({
                url: ajax.url,
                type: 'POST',
                data: {
                    action: 'filter_tours',
                    security: ajax.nonce,
                    selectedCategories: selectedCategories,
                },
                beforeSend: function () {
                    $('.js-tours-col').addClass('is-filtering');
                    $filterToursButton.text('Czekaj');
                    $filterToursButton.prop('disabled', true);
                },
                success: function (response) {
                    response = JSON.parse(response);

                    if (response.status == true) {
                        $filterToursButton.text('Filtruj');
                        $filterToursButton.prop('disabled', false);
                        $('.js-tours-col').html(response.html);
                        $('.js-tours-col').removeClass('is-filtering');
                        
                    } else {
                        $filterToursButton.text('Błąd');
                        console.error(response);
                    }
                },
                error: function (xhr, error) {
                    $filterToursButton.text('Błąd');
                    console.error(error);
                }
            });

        });
    }

})(jQuery);
