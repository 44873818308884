/**
 * Funkcja odpowiada za ukrycie preloadera po zakończeniu ładowania strony
 * oraz zapisanie ciastka, dzięki któremu preloader pojawi się tylko raz w ciągu dnia.
 * 
 * @version 1.1.0
 */
(function ($) {

    $(window).on('load', function() {
        if ($(".js-preloader").length > 0) {
            $(".js-preloader").addClass('preloader--hide');
        }

        if (typeof Cookies.get('tommi_tours_preloader') === 'undefined'){
            Cookies.set('tommi_tours_preloader', 'Yup', {
                expires: 1,
                secure: location.protocol == 'https:' ? true : false,
            });
        }
    });

})(jQuery);
