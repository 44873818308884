/**
 * Plik zawiera skrypty odpowiadające za floating buttons - to-top oraz call-to.
 * @version 1.0.0
 */

(function ($) {

    // Wyświetlanie przycisków na scroll.
    $(window).on('scroll', function() {
        let scrollTopPosition = $(window).scrollTop(),
            scrollBottomPosition = $(window).scrollTop() + $(window).height(),
            documentHeight = $(document).height();
        if (scrollTopPosition > 300) {
            $('.js-floating-button').addClass('floating-button--show');
        } else {
            $('.js-floating-button').removeClass('floating-button--show');
        }

        // Podniesienie przycisków na 1366x768px na dole strony.
        if ((documentHeight - 100) < scrollBottomPosition) {
            $('.js-floating-button').addClass('is-bottom');
        } else {
            $('.js-floating-button').removeClass('is-bottom');
        }
    });

    $(window).on('load', function () {
        // Call To Button
        $('.js-floating-call-to-button').on('click', function () {
            $(this).toggleClass('is-opened');
        });
    }); 

})(jQuery);
