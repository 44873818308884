/**
 * Plik odpowiada za zarządzanie formularzem zapisu na wycieczkę.
 * @version 1.0.0
 */
(function ($) {

    $(window).on('load', function() {
        let $tourReservationForm = $('.js-tour-reservation-form');

        $tourReservationForm.find('.js-input-post-code').mask('00-000');
        $tourReservationForm.find('.js-input-birthday-date').mask('A0-B0-C000', {
            translation: {
                'A': {
                    pattern: /[0-3]/,
                    optional: false
                },
                'B': {
                    pattern: /[0-1]/,
                    optional: false
                },
                'C': {
                    pattern: /[0-2]/,
                    optional: false
                }
            }
        });

        $tourReservationForm.on('submit', function (e) {
            e.preventDefault();

            let formData = $tourReservationForm.serializeArray().reduce(function(obj, item) {
                    obj[item.name] = item.value;
                    return obj;
                }, {}),
                sendButton = $('.js-register-button');

            $.ajax({
                url: ajax.url,
                type: 'POST',
                data: {
                    action: 'tour_reservation_add_person',
                    security: ajax.nonce,
                    data: formData,
                },
                beforeSend: function () {
                    sendButton.text('Czekaj');
                    sendButton.prop('disabled', true);
                },
                success: function (response) {
                    if (response == 'OK') {
                        sendButton.text('Zapisano');
                        $('#collapseReservationForm').collapse('hide');
                        $('#collapseReservationFormEnd').collapse('show');
                    } else {
                        sendButton.text('Błąd');
                        console.error(response);
                    }
                },
                error: function (xhr, error) {
                    sendButton.text('Błąd');
                    console.error(error);
                }
            });

        });
    });

})(jQuery);
